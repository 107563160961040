import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SclaAppStatusTag from '../tags/SclaAppStatusTag';
import SclaAppSectionsCompleteTag from '../tags/SclaAppSectionsCompleteTag';

interface ISclaReturnerAppCellProps {
  rowData: any;
  dataKey: string;
}

const CellContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
function SclaReturnerAppCell({
  dataKey,
  rowData,
}: ISclaReturnerAppCellProps) {
  const [sectionsComplete, setSectionsComplete] = useState(0);

  useEffect(() => {
    let count = 0;

    const {
      backgroundInfo,
      familyInfo,
      schoolInfo,
      promptInfo,
      transcriptsInfo,
      recInfo,
      signatureInfo,
    } = rowData;

    if (backgroundInfo.sectionComplete) count += 1;
    if (familyInfo.sectionComplete) count += 1;
    if (schoolInfo.sectionComplete) count += 1;
    if (promptInfo.sectionComplete) count += 1;
    if (transcriptsInfo.sectionComplete) count += 1;
    if (recInfo.sectionComplete) count += 1;
    if (signatureInfo.sectionComplete) count += 1;

    setSectionsComplete(count);
  }, [rowData]);
  return (
    <CellContainer>
      <SclaAppSectionsCompleteTag size="md" count={sectionsComplete} />
    </CellContainer>
  );
}

export default SclaReturnerAppCell;
