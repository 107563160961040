import React from 'react';
import DataTable from 'components/Elements/Table/DataTable';
import { TableActionFilter, TableActions } from 'components/Elements/Table/TableActions';
import useAppNavigation from 'hooks/useAppNavigation';
import styled from 'styled-components';
import useRosieUserTable from '../hooks/useRosieUserTable';
import DeleteRosieUserModal from './DeleteRosieUserModal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TableContainer = styled.div`
  flex: 1;
  overflow: hidden;
`;

interface IRosieUserTableProps {
  userType: string;
  isDone: boolean;
  showFilters: boolean;
  page: number;
  limit: number;
  sortColumn: string;
  sortType: string;
  search: {
    label: string;
    value: string;
    onSearch: (val: string) => void;
  };
  filters: TableActionFilter[];
  onSortColumn: (newSortColumn: string, newSortType: any) => void;
  onPageChange: (dataKey: number) => void;
  onChangeLimit: (dataKey: number) => void;
}

/**
 * Renders data table for scla applications
 * @returns
 */
export default function RosieUserTable(props: IRosieUserTableProps) {
  const {
    isDone,
    userType,
    sortType,
    page,
    limit,
    sortColumn,
    search,
    showFilters,
    filters,
    onPageChange,
    onChangeLimit,
    onSortColumn,
  } = props;

  const {
    userList,
    isDoneLoading,
    defaultColumns,
    columnKeys,
    columns,
    deleteUser,
    isDoneDeleting,
    onColumnKeysChange,
    totalCount,
    showDeleteDialog,
    closeDialog,
    confirmUserDelete,
  } = useRosieUserTable(
    page,
    limit,
    sortColumn,
    sortType,
    search.value,
    userType,
    isDone,
  );

  const { navToRosieUserDetails } = useAppNavigation();

  return (
    <Container>
      <DeleteRosieUserModal
        open={showDeleteDialog}
        isLoading={isDoneDeleting}
        onClose={closeDialog}
        onDelete={deleteUser}
      />

      {showFilters && (
        <TableActions
          defaultColumns={defaultColumns}
          columnKeys={columnKeys}
          onColumnKeysChange={onColumnKeysChange}
          search={search}
          filters={filters}
        />
      )}

      <TableContainer>
        <DataTable
          showFilters={showFilters}
          menuItems={[
            {
              label: 'View Details',
              onSelect: navToRosieUserDetails,
            },
            {
              label: 'Delete User',
              onSelect: confirmUserDelete,
            },
          ]}
          data={userList}
          length={totalCount}
          columns={columns}
          isLoading={!isDoneLoading}
          sortColumn={sortColumn}
          sortType={sortType as any}
          limit={limit}
          page={page}
          onSortColumn={onSortColumn}
          onPageChange={onPageChange}
          onChangeLimit={onChangeLimit}
        />
      </TableContainer>
    </Container>
  );
}
