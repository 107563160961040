import { PICKER_ROSIE_USER_TYPE } from 'constants/picker';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

function isUserTypeValid(type: string): boolean {
  let isValid = false;

  switch (type) {
    case 'admin':
      isValid = true;
      break;
    case 'super-admin':
      isValid = true;
      break;
    case 'user':
      isValid = true;
      break;
    case 'guest':
      isValid = true;
      break;
    case 'all':
      isValid = true;
      break;
    default:
      break;
  }

  return isValid;
}

function isPageValid(page: string): boolean {
  const parsed = parseInt(page, 10) || 0;

  if (parsed <= 0) {
    return false;
  }
  return true;
}

function getValidPage(page: string): number {
  const parsed = parseInt(page, 10) || 1;
  if (parsed <= 0) {
    return 1;
  }
  return parsed;
}

function getValidUserType(type: string): string {
  if (isUserTypeValid(type)) {
    return type;
  }
  return 'all';
}

const searchLabel = 'Search users by name.';

export default function useRosieUserTableSearchParam() {
  const [isDone, setIsDone] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showFilters, setShowFilters] = useState(true);

  const querySearch = searchParams.get('search') as string;
  const queryPage = searchParams.get('page') as string;
  const queryLimit = searchParams.get('limit') as string;
  const querySortColumn = searchParams.get('sortColumn') as string;
  const querySortType = searchParams.get('sortType') as any;
  const queryUserType = searchParams.get('userType') as any;


  const tableConfig = {
    page: queryPage ? `${getValidPage(queryPage)}` : `1`,
    limit: queryLimit || '10',
    sortColumn: querySortColumn || 'createdOn',
    sortType: querySortType || 'desc',
    search: querySearch || '',
    userType: queryUserType ? `${getValidUserType(queryUserType)}` : `all`,
  };

  useEffect(() => {
    setIsDone(false);
    let update = false;

    if (
      queryPage === null ||
      queryLimit === null ||
      querySortType === null ||
      querySortColumn === null ||
      querySearch === null ||
      queryUserType === null
    ) {
      update = true;
    } else if (queryPage && !isPageValid(queryPage)) {
      update = true;
    } else if (queryUserType && !isUserTypeValid(queryPage)) {
      update = true;
    }

    if (update) {
      setSearchParams(tableConfig);
      setIsDone(true);
    }
  }, [queryPage, queryLimit, querySortColumn, querySortType, querySearch, queryUserType]);

  const onPageChange = (page: number) => {
    setSearchParams({ ...tableConfig, page: `${page}` });
  };

  const onChangeLimit = (limit: number) => {
    setSearchParams({ ...tableConfig, limit: `${limit}` });
  };

  const onSortColumn = (sortColumn: string, sortType: any) => {
    setSearchParams({ ...tableConfig, sortColumn, sortType });
  };

  const onSearch = (search: string) => {
    setSearchParams({ ...tableConfig, search });
  };

  /**
   * debounce search with 300ms wait time
   * Ignore continuos search calls until  timer has
   * elapsed
   *
   */
  const debouncedSearchHandler = useMemo(
    () => debounce(onSearch, 300),
    [tableConfig],
  );

  const clearUserType = () => {
    setSearchParams({ ...tableConfig, userType: 'all' });
  };

  const onSelectUserType = (filter: string | null) => {
    if (filter) {
      setSearchParams({ ...tableConfig, userType: filter });
    }
  };

  return {
    isDone,
    page: queryPage ? parseInt(queryPage, 10) : 1,
    limit: queryLimit ? parseInt(queryLimit, 10) : 10,
    sortColumn: querySortColumn,
    sortType: querySortType,
    userType: queryUserType,
    onPageChange,
    onChangeLimit,
    onSortColumn,
    search: {
      label: searchLabel,
      onSearch: debouncedSearchHandler,
      value: querySearch,
    },
    filters:[ {
      value: queryUserType,
      pickerOptions: PICKER_ROSIE_USER_TYPE,
      pickerLabel: 'Filter by type',
      valueLabel: '',
      clearFilters: clearUserType,
      onFilterChange: onSelectUserType,

    }],
    showFilters,
    setShowFilters,
  };
}
