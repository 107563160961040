import React from 'react';
import { Tag } from 'rsuite';
import styled from 'styled-components';
import {
  TAG_COLOR_BLUE,
  TAG_COLOR_GREEN,
  TAG_COLOR_RED,
} from '../../../../constants/colors';
import { SclaAppStatus } from '../../types';

interface ISclaTypeTagProps {
  type: SclaAppStatus;
  size: 'lg' | 'md' | 'sm' | undefined;
}

const getColor = (type: 'lil' | 'txter') => {
  if (type === 'txter') return TAG_COLOR_BLUE;

  return TAG_COLOR_GREEN;
};

const CustomTag = styled(Tag)`
  text-transform: lowercase;
  color: #fff;
  background-color: ${props => getColor(props.type)};
`;

export default function SclaTypeTag(props: ISclaTypeTagProps) {
  const { type, size } = props;

  return (
    <CustomTag type={type} size={size}>
      {type}
    </CustomTag>
  );
}
