// eslint-disable-next-line import/prefer-default-export
export const ROSIE_USER_TYPE = [
  {
    value: 'all',
    label: 'all',
  },
  {
    value: 'admin',
    label: 'admin',
  },
  {
    value: 'super-admin',
    label: 'super-admin',
  },
  {
    value: 'user',
    label: 'user',
  },

  {
    value: 'guest',
    label: 'guest',
  },
];
