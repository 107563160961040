import { AxiosResponse } from 'axios';
import { AuthError, getAuth, signOut } from 'firebase/auth';
import { IServerErrorResponse } from 'types';
import { dispatchLogout } from 'store/dispatcher';
import { openErrorToaster } from './toast.service';

const ERROR_TOAST_TIMER = 10000;

function defaultErrorHandler() {
  // show default error alert
  openErrorToaster(
    'Server Error. Please refresh the page and try again.',
    ERROR_TOAST_TIMER,
  );
}

/**
 * Clears user session and logs user out
 * @param message
 * @param logout
 */
async function handleInvalidSession(message: string) {
  try {
    // get user session
    const auth = getAuth();
    // sign out user
    await signOut(auth);
    // clear user session
    dispatchLogout();
    // show error alert
    openErrorToaster(message, ERROR_TOAST_TIMER);
  } catch (e) {
    openErrorToaster(
      'An error occurred. Please refresh the page and try again.',
      ERROR_TOAST_TIMER,
    );
  }
}

function axiosServerErrorHandler(
  response: AxiosResponse<IServerErrorResponse>,
) {
  if (response.status === 403) {
    handleInvalidSession(response.data.message);
  } else if (response.status === 404) {
    // show error alert
    openErrorToaster(
      'Server Error. Please refresh the page and try again.',
      ERROR_TOAST_TIMER,
    );
  } else {
    // show error alert
    openErrorToaster(response.data.message, ERROR_TOAST_TIMER);
  }
}

function firebaseAuthErrorHandler(error: AuthError) {
  const authError = error as AuthError;
  const errorCode = authError.code;

  switch (errorCode) {
    case 'auth/too-many-requests':
      openErrorToaster(
        'Account login disabled due to too failed attempts.',
        ERROR_TOAST_TIMER,
      );
      break;
    case 'auth/invalid-credential':
      openErrorToaster(
        'Invalid Email/Password combination.',
        ERROR_TOAST_TIMER,
      );
      break;
    case 'auth/user-not-found':
      openErrorToaster(
        'An account with that email does not exist',
        ERROR_TOAST_TIMER,
      );
      break;
    case 'auth/wrong-password':
      openErrorToaster(
        'Invalid Email/Password combination.',
        ERROR_TOAST_TIMER,
      );
      break;
    case 'auth/email-already-in-use':
      openErrorToaster(
        'An account with that email already exists.',
        ERROR_TOAST_TIMER,
      );
      break;

    case 'auth/weak-password':
      openErrorToaster(
        'Password must be at least characters long.',
        ERROR_TOAST_TIMER,
      );
      break;

    case 'auth/user-token-expired':
      openErrorToaster(
        'Password must be at least characters long.',
        ERROR_TOAST_TIMER,
      );
      dispatchLogout(); // clear user session data
      break;

    case 'auth/invalid-user-token':
      openErrorToaster(
        'Password must be at least characters long.',
        ERROR_TOAST_TIMER,
      );
      dispatchLogout(); // clear user session data
      break;

    default:
      defaultErrorHandler();
      break;
  }
}

/**
 * This method handles server errors. An danger alert is
 * displayed with relevant info
 * @param error
 * @param logout
 */
const serverErrorHandler = (error: any) => {
  console.log(error);
  // handle firebase errors
  if (error.code) {
    firebaseAuthErrorHandler(error);
  } else if (error.response) {
    axiosServerErrorHandler(error.response);
  } else {
    defaultErrorHandler();
  }
};

export default serverErrorHandler;
