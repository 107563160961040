import React from 'react'
import { Tag } from 'rsuite';
import styled from 'styled-components';
import { TAG_COLOR_BLUE, TAG_COLOR_GREEN } from '../../../constants/colors';

export type TxterProfileType = 'alumni' | 'txter';

export interface IUserTypeTagProps {
  type: any;
  size: 'lg' | 'md' | 'sm' | undefined;
}

const getColor = (type:any) => {
  if (type === 'super-admin') return TAG_COLOR_GREEN;

  return TAG_COLOR_BLUE;
}

const ProfileTag = styled(Tag)`
text-transform: capitalize;
color: #fff;
background-color: ${props => getColor(props.type)};
`

export function UserTypeTag(props: IUserTypeTagProps) {
  const { type, size } = props;
  return (
    <ProfileTag size={size}>
      {type}
    </ProfileTag>
  );
}
